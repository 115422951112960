import Layout from "./Layout/Layout";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
const BlogSectionDeatials = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Layout>
        <div className="Blog_section_section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 Text_blog_section">
                <h6>Type of Psychologist </h6>
                <h1>
                  What is a blog? Definition, types, benefits and why you need
                  one
                </h1>
                <h6>5 AUG 2023 BY SP. JAIN, Ph.D</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="Blog_section_text_section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-8 text_blog_section">
                <p>
                  Consultant Teacher's Name holds a Degree in Field from
                  University and has specialized in specific areas of expertise,
                  such as curriculum development, educational technology,
                  special education, etc. Known for their engaging teaching
                  style and ability to connect with students, Consultant
                  Teacher's Name has a proven track record of helping students
                  achieve their academic goals and develop a lifelong passion
                  for learning.
                </p>
              </div>
              <div className="col-sm-4 images_section">
                <img src="/Asstes/Images/Rectangle 163767 (1).png" />
              </div>
              <div className="col-12">
                <p>
                  Consultant Teacher's Name holds aDegree inField fromUniversity
                  and has specialized inspecific areas of expertise, such as
                  curriculum development, educational technology, special
                  education, etc. Known for their engaging teaching style and
                  ability to connect with students,Consultant Teacher's Name has
                  a proven track record of helping students achieve their
                  academic goals and develop a lifelong passion for learning.
                </p>
                <p>
                  Consultant Teacher's Name holds aDegree inField fromUniversity
                  and has specialized inspecific areas of expertise, such as
                  curriculum development, educational technology, special
                  education, etc. Known for their engaging teaching style and
                  ability to connect with students,Consultant Teacher's Name has
                  a proven track record of helping students achieve their
                  academic goals and develop a lifelong passion for learning.
                </p>
                <p>
                  Consultant Teacher's Name holds aDegree inField fromUniversity
                  and has specialized inspecific areas of expertise, such as
                  curriculum development, educational technology, special
                  education, etc. Known for their engaging teaching style and
                  ability to connect with students,Consultant Teacher's Name has
                  a proven track record of helping students achieve their
                  academic goals and develop a lifelong passion for learning.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="Blog_section_text_section">
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 images_section">
                    <img src="/Asstes/Images/Rectangle 163768 (1).png" />
                </div>
            </div>
        </div>
        </div>
        <div className="Blog_section_text_section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <p>
                  Consultant Teacher's Name holds aDegree inField fromUniversity
                  and has specialized inspecific areas of expertise, such as
                  curriculum development, educational technology, special
                  education, etc. Known for their engaging teaching style and
                  ability to connect with students,Consultant Teacher's Name has
                  a proven track record of helping students achieve their
                  academic goals and develop a lifelong passion for learning.
                </p>
                <p>
                  Consultant Teacher's Name holds aDegree inField fromUniversity
                  and has specialized inspecific areas of expertise, such as
                  curriculum development, educational technology, special
                  education, etc. Known for their engaging teaching style and
                  ability to connect with students,Consultant Teacher's Name has
                  a proven track record of helping students achieve their
                  academic goals and develop a lifelong passion for learning.
                </p>
                <p>
                  Consultant Teacher's Name holds aDegree inField fromUniversity
                  and has specialized inspecific areas of expertise, such as
                  curriculum development, educational technology, special
                  education, etc. Known for their engaging teaching style and
                  ability to connect with students,Consultant Teacher's Name has
                  a proven track record of helping students achieve their
                  academic goals and develop a lifelong passion for learning.
                </p>
                <p>
                  Consultant Teacher's Name holds aDegree inField fromUniversity
                  and has specialized inspecific areas of expertise, such as
                  curriculum development, educational technology, special
                  education, etc. Known for their engaging teaching style and
                  ability to connect with students,Consultant Teacher's Name has
                  a proven track record of helping students achieve their
                  academic goals and develop a lifelong passion for learning.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="BLog_section_main">
          <div className="container-fluid Blog_section_all">
            <div className="Blog_other">
              <h1>Other Blogs </h1>
            </div>
            <div className="row">
              <div className="col-lg-4 Blog_section_text_main">
                <div className="Blog_images_section_all">
                  <div className="Blog_images_section_text">
                    <img src="/Asstes/Images/Rectangle 163851 (1).png" alt="images"/>
                  </div>

                  <h6>Sharma kumar</h6>
                  <p>
                    With Over 18 years of Experience in the field of counselling
                    Annant Education is a leading consulting services provider
                    company in Delhi NCR region, established by Mrs. Arti Tomar.
                    We have been providing quality consulting services in the
                    Education sector. We have accepted as a preferred
                    organization for undertaking consultancy assignments.
                  </p>
                
                  <Link to="/BlogSectionDeatials">
                  <button>Read more</button>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 Blog_section_text_main">
                <div className="Blog_images_section_all">
                  <div className="Blog_images_section_text">
                    <img src="/Asstes/Images/Rectangle 163851 (1).png" alt="images"/>
                  </div>

                  <h6>Sharma kumar</h6>
                  <p>
                    With Over 18 years of Experience in the field of counselling
                    Annant Education is a leading consulting services provider
                    company in Delhi NCR region, established by Mrs. Arti Tomar.
                    We have been providing quality consulting services in the
                    Education sector. We have accepted as a preferred
                    organization for undertaking consultancy assignments.
                  </p>
                  <Link to="/BlogSectionDeatials">
                  <button>Read more</button>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 Blog_section_text_main">
                <div className="Blog_images_section_all">
                  <div className="Blog_images_section_text">
                    <img src="/Asstes/Images/Rectangle 163851 (1).png" alt="images"/>
                  </div>

                  <h6>Sharma kumar</h6>
                  <p>
                    With Over 18 years of Experience in the field of counselling
                    Annant Education is a leading consulting services provider
                    company in Delhi NCR region, established by Mrs. Arti Tomar.
                    We have been providing quality consulting services in the
                    Education sector. We have accepted as a preferred
                    organization for undertaking consultancy assignments.
                  </p>
                  <Link to="/BlogSectionDeatials">
                  <button>Read more</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};
export default BlogSectionDeatials;
