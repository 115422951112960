import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'remixicon/fonts/remixicon.css'
import Home from "./components/Layout/Home";
import BookAppoinment from "./components/BookAppoinments";
import BlogSectionDeatials from "./components/BlogSection_Deatials";
import Blogsection from "./components/BlogSection"
import Term from "./components/Term";
import Booking from "./components/Booking";
function App() {
  return (
    <div className="">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={ <Home />}> </Route>
          <Route path="BookAppoinment" element={ <BookAppoinment />}></Route>
          <Route path="BlogSectionDeatials" element={ <BlogSectionDeatials />}></Route>
          <Route path="Blogsection" element={ <Blogsection />}></Route>
          <Route path="Term" element={ <Term />}></Route>
          <Route path="Booking" element={ <Booking />}></Route>
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;