import Layout from "./Layout/Layout";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
const Blogsection = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Layout>
        <div className="Bolg_section_deatials">
          <div className="container-fluid">
            <div className="row">
              <div className="col-7">
                <h6>
                  Home / <span style={{ color: "#D4B26B" }}>Blog</span>
                </h6>
              </div>
              <div className="col-5">
                <img src="/Asstes/Images/Group 22.png" />
              </div>
            </div>
          </div>
        </div>
        <div className="Blog_search_filuter">
          <div className="container-fluid">
            <div className="row">
              <div className="col-8 Search_section">
                <input type="text" name="text" placeholder="search" />
              </div>
              <div className="col-4 Choose_pages_section">
                <select>
                  <option>Filter</option>
                  <option>Filter</option>
                  <option>Filter</option>
                  <option>Filter</option>
                  <option>Filter</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="BLog_section_main">
          <div className="container-fluid Blog_section_all">
            <div className="row">
              <div className="col-lg-4 Blog_section_text_main">
                <div className="Blog_images_section_all">
                  <div className="Blog_images_section_text">
                    <img src="/Asstes/Images/Rectangle 163851 (1).png" alt="images"/>
                  </div>

                  <h6>Sharma kumar</h6>
                  <p>
                    With Over 18 years of Experience in the field of counselling
                    Annant Education is a leading consulting services provider
                    company in Delhi NCR region, established by Mrs. Arti Tomar.
                    We have been providing quality consulting services in the
                    Education sector. We have accepted as a preferred
                    organization for undertaking consultancy assignments.
                  </p>
                
                  <Link to="/BlogSectionDeatials">
                  <button>Read more</button>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 Blog_section_text_main">
                <div className="Blog_images_section_all">
                  <div className="Blog_images_section_text">
                    <img src="/Asstes/Images/Rectangle 163851 (1).png" alt="images"/>
                  </div>

                  <h6>Sharma kumar</h6>
                  <p>
                    With Over 18 years of Experience in the field of counselling
                    Annant Education is a leading consulting services provider
                    company in Delhi NCR region, established by Mrs. Arti Tomar.
                    We have been providing quality consulting services in the
                    Education sector. We have accepted as a preferred
                    organization for undertaking consultancy assignments.
                  </p>
                  <Link to="/BlogSectionDeatials">
                  <button>Read more</button>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 Blog_section_text_main">
                <div className="Blog_images_section_all">
                  <div className="Blog_images_section_text">
                    <img src="/Asstes/Images/Rectangle 163851 (1).png" alt="images"/>
                  </div>

                  <h6>Sharma kumar</h6>
                  <p>
                    With Over 18 years of Experience in the field of counselling
                    Annant Education is a leading consulting services provider
                    company in Delhi NCR region, established by Mrs. Arti Tomar.
                    We have been providing quality consulting services in the
                    Education sector. We have accepted as a preferred
                    organization for undertaking consultancy assignments.
                  </p>
                  <Link to="/BlogSectionDeatials">
                  <button>Read more</button>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 Blog_section_text_main">
                <div className="Blog_images_section_all">
                  <div className="Blog_images_section_text">
                    <img src="/Asstes/Images/Rectangle 163851 (1).png" alt="images"/>
                  </div>

                  <h6>Sharma kumar</h6>
                  <p>
                    With Over 18 years of Experience in the field of counselling
                    Annant Education is a leading consulting services provider
                    company in Delhi NCR region, established by Mrs. Arti Tomar.
                    We have been providing quality consulting services in the
                    Education sector. We have accepted as a preferred
                    organization for undertaking consultancy assignments.
                  </p>
                  <Link to="/BlogSectionDeatials">
                  <button>Read more</button>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 Blog_section_text_main">
                <div className="Blog_images_section_all">
                  <div className="Blog_images_section_text">
                    <img src="/Asstes/Images/Rectangle 163851 (1).png" alt="images"/>
                  </div>

                  <h6>Sharma kumar</h6>
                  <p>
                    With Over 18 years of Experience in the field of counselling
                    Annant Education is a leading consulting services provider
                    company in Delhi NCR region, established by Mrs. Arti Tomar.
                    We have been providing quality consulting services in the
                    Education sector. We have accepted as a preferred
                    organization for undertaking consultancy assignments.
                  </p>
                  <Link to="/BlogSectionDeatials">
                  <button>Read more</button>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 Blog_section_text_main">
                <div className="Blog_images_section_all">
                  <div className="Blog_images_section_text">
                    <img src="/Asstes/Images/Rectangle 163851 (1).png" alt="images"/>
                  </div>

                  <h6>Sharma kumar</h6>
                  <p>
                    With Over 18 years of Experience in the field of counselling
                    Annant Education is a leading consulting services provider
                    company in Delhi NCR region, established by Mrs. Arti Tomar.
                    We have been providing quality consulting services in the
                    Education sector. We have accepted as a preferred
                    organization for undertaking consultancy assignments.
                  </p>
                  <Link to="/BlogSectionDeatials">
                  <button>Read more</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};
export default Blogsection;
