import "animate.css";
import React from "react";
import "react-alice-carousel/lib/alice-carousel.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import "swiper/css";
import Layout from "./Layout";
import "aos/dist/aos.css";
import { useEffect } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
const data = [
  {
    title: "What types of counseling services do you offer?",
    content:
      "We offer a wide range of counseling services, including individual therapy, couples counseling, family therapy, and group therapy. Our specialties include anxiety, depression, stress management, relationship issues, grief counseling, and more.",
  },
  {
    title: "How do I schedule an appointment?",
    content:
      "We offer a wide range of counseling services, including individual therapy, couples counseling, family therapy, and group therapy. Our specialties include anxiety, depression, stress management, relationship issues, grief counseling, and more.",
  },
  {
    title: "Is counseling confidential?",
    content:
      "We offer a wide range of counseling services, including individual therapy, couples counseling, family therapy, and group therapy. Our specialties include anxiety, depression, stress management, relationship issues, grief counseling, and more.",
  },
  {
    title: "What should I expect during my first counseling session?",
    content:
      "We offer a wide range of counseling services, including individual therapy, couples counseling, family therapy, and group therapy. Our specialties include anxiety, depression, stress management, relationship issues, grief counseling, and more.",
  },
  {
    title: "Do you accept insurance, and what are your fees?",
    content:
      "We offer a wide range of counseling services, including individual therapy, couples counseling, family therapy, and group therapy. Our specialties include anxiety, depression, stress management, relationship issues, grief counseling, and more.",
  },
];

const Home = () => {
  const responsive = {
    0: { items: 1 },
    768: { items: 1 },
    1024: { items: 1 },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div>
      <Layout>
        <div className="Home_banner_section">
          <div className="container-fluid ">
            <div className="row">
              <div className="col-lg-6 Home_content_section">
                <h1>
                  Empowering you to find peace and clarity in <br></br>
                  <span style={{ color: "#AF864E" }}>your life's journey.</span>
                </h1>
                <p>
                  Welcome to Annant Education Counseling: A Safe Space for
                  Healing. Experienced Therapists Ready to Support You. Our goal
                  is to establish a friendly, safe environment where you may
                  freely express your emotions.
                </p>
                <div className="Button_section_home">
                <Link to="/BookAppoinment"><button className="Book_Appoinment">Book appointment</button></Link>
                  <button className="Book_Know">know more</button>
                </div>
              </div>
              <div className="col-lg-6 Home_Images_section">
                <img src="/Asstes/Images/Home.png" />
              </div>
            </div>
          </div>
        </div>
        <div className="Collage_section_main">
          <div className="container-fluid Collages_section">
            <div className="row">
              <div className="col-lg-3 Cloages_Parent_section">
                <div className="Child_section">
                  <center>
                    <div>
                      <img src="/Asstes/Images/college 1.svg" />
                    </div>
                    <h5>
                      Education<br></br>
                      Consulting
                    </h5>
                  </center>
                </div>
              </div>
              <div className="col-lg-3 Cloages_Parent_section">
                <div className="Child_section">
                  <center>
                    <div>
                      <img src="/Asstes/Images/mentorship 1.svg" />
                    </div>
                    <h5>
                      Career Counseling<br></br>& Guidance
                    </h5>
                  </center>
                </div>
              </div>
              <div className="col-lg-3 Cloages_Parent_section">
                <div className="Child_section">
                  <center>
                    <div>
                      <img
                        src="/Asstes/Images/relationship-counseling.svg"
                        style={{
                          width: "60px",
                          height: "60px",
                          marginTop: "20px",
                        }}
                      />
                    </div>
                    <h5 style={{ paddingTop: "30px" }}>
                      Relationship<br></br>
                      counseling
                    </h5>
                  </center>
                </div>
              </div>
              <div
                className="col-lg-3 Cloages_Parent_section"
                style={{ paddingRight: "0" }}
              >
                <div className="Child_section">
                  <center>
                    <div>
                      <img
                        src="/Asstes/Images/marriage-counseling (1) 1.png"
                        style={{
                          width: "60px",
                          height: "60px",
                          marginTop: "20px",
                        }}
                      />
                    </div>
                    <h5 style={{ paddingTop: "30px" }}>
                      Marriage<br></br>
                      counseling
                    </h5>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="About_section_main_main"id="About">
          <div className="container-fluid About_section-main">
            <div className="row">
              <div className="col-lg-4 Parent_section">
                <h2>ABOUT US </h2>
                <h5>
                  At Annant Education, we believe <br></br>in guiding you
                  towards a <br></br>
                  <span style={{ color: "#D4B26B" }}> brighter future. </span>
                </h5>
                <p>
                  With Over 18 years of Experience in the field of counselling
                  Annant Education is a leading consulting services provider
                  company in Delhi NCR region, established by Mrs. Arti Tomar.
                  We have been providing quality consulting services in the
                  Education sector. We have accepted as a preferred organization
                  for undertaking consultancy assignments.
                </p>
              </div>
              <div className="col-lg-4 Parent_section">
                <img src="/Asstes/Images/About.png" alt="images" />
              </div>
              <div className="col-lg-4 Parent_section">
                <h2>Why Choose us</h2>
                <h5>
                  Expert Guidance Our experienced counselors provide
                  personalized advice to help you achieve your{" "}
                  <span style={{ color: "#D4B26B" }}>educational goals.</span>
                </h5>
                <p>
                  With Over 18 years of Experience in the field of counselling
                  Annant Education is a leading consulting services provider
                  company in Delhi NCR region, established by Mrs. Arti Tomar.
                  We have been providing quality consulting services in the
                  Education sector. We have accepted as a preferred organization
                  for undertaking consultancy assignments.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="No1_Career_Cansulting_section">
          <div className="container No1_Career_Cansulting_section_Main_section">
            <div className="row">
              <div className="col-3 No1_Career_Cansulting_section_Main_section_child_section">
                <center>
                  <img src="/Asstes/Images/Group 78.svg" alt="images" />
                  <div>
                    <h1>No.1</h1>
                    <p>
                      Career Cansulting<br></br> platform in India{" "}
                    </p>
                  </div>
                </center>
              </div>
              <div className="col-3 No1_Career_Cansulting_section_Main_section_child_section">
                <center>
                  <img src="/Asstes/Images/Group 78.svg" alt="images" />
                  <div>
                    <h1>50+</h1>
                    <p> Consutant</p>
                  </div>
                </center>
              </div>
              <div className="col-3 No1_Career_Cansulting_section_Main_section_child_section">
                <center>
                  <img src="/Asstes/Images/Group 78.svg" alt="images" />
                  <div>
                    <h1>5Lacs+ </h1>
                    <p>Satisfied persons</p>
                  </div>
                </center>
              </div>
              <div className="col-3 No1_Career_Cansulting_section_Main_section_child_section">
                <center>
                  <img src="/Asstes/Images/Group 78.svg" alt="images" />
                  <div>
                    <h1>96% </h1>
                    <p>success rate</p>
                  </div>
                </center>
              </div>
            </div>
          </div>
        </div>
        <div className="No1_Career_Cansulting_section_mobile_section">
          <div className="row">
            <div className="col-12 Mobile_section_counsoling">
              <div className="Images_section_mobile">
                <img src="/Asstes/Images/Group 78.svg" alt="images" />
              </div>
              <div className="TExt_section_mobile_view">
                <h1>No.1</h1>
                <p>Career Cansulting platform in India </p>
              </div>
            </div>
            <div className="col-12 Mobile_section_counsoling">
              <div className="Images_section_mobile">
                <img src="/Asstes/Images/Group 78.svg" alt="images" />
              </div>
              <div className="TExt_section_mobile_view">
                <h1>50+</h1>
                <p> Consutant</p>
              </div>
            </div>
            <div className="col-12 Mobile_section_counsoling">
              <div className="Images_section_mobile">
                <img src="/Asstes/Images/Group 78.svg" alt="images" />
              </div>
              <div className="TExt_section_mobile_view">
                <h1>5 Lacs+ </h1>
                <p>Satisfied persons</p>
              </div>
            </div>
            <div className="col-12 Mobile_section_counsoling">
              <div className="Images_section_mobile">
                <img src="/Asstes/Images/Group 78.svg" alt="images" />
              </div>
              <div className="TExt_section_mobile_view">
                <h1>96% </h1>
                <p>success rate</p>
              </div>
            </div>
          </div>
        </div>
        <div className="Gallery_section_main"id="Gallery">
          <div className="Gellery_Sectound_section">
            <div className="container-fluid Gallery_section_container">
              <h1>Gallery</h1>
              <div className="row">
                <div className="col-lg-2 Gallery_section_2">
                  <div className="Gallery_images_section">
                    <img src="/Asstes/Images/Gellory (4).png" />
                  </div>
                </div>
                <div className="col-lg-5 Gallery_section_4">
                  <div className="Gallery_section_images">
                    <img src="/Asstes/Images/Gellory (3).png" />
                  </div>
                </div>
                <div className="col-lg-5 Gallery_section_4">
                  <div className="Gallery_section_images">
                    <img src="/Asstes/Images/Gellory (2).png" />
                  </div>
                </div>
                <div className="col-lg-4 Gallery_secound_section">
                  <div className="row Gallery_images_section_secound">
                    <div className="col-12 Gellory_secound_all_imasges">
                      <img src="/Asstes/Images/Rectangle 163843.png" />
                    </div>
                    <div className="col-12 Gellory_secound_all_imasges">
                      <img src="/Asstes/Images/Rectangle 163840.png" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 Gallery_secound_images_section">
                  <div className="Secound_images_section_right">
                    <img src="/Asstes/Images/Gellory (1).png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="Team_secing_main">
          <div className="TEam_section_secound">
            <div className="container-fluid Team_section_all">
              <div className="Events_section">
                <h1>Upcoming Events </h1>
              </div>
              <div className="row Events_row">
                <AliceCarousel
                  autoPlayInterval={4000}
                  autoPlay={true}
                  fadeOutAnimation={true}
                  mouseTrackingEnabled={true}
                  disableDotsControls
                  disableButtonsControls
                  infinite
                  disableAutoPlayOnAction={true}
                >
                  <div className="col-12 Images_Event_section">
                   <div className="row">
                   <div className="col-lg-3 Images">
                      {" "}
                      <img src="/Asstes/Images/Gellory (2).png" alt="images" />
                    </div>
                    <div className="col-lg-9 TExt_section_main">
                      <h4>Education Consulting Events</h4>
                      <h6>Location :- ashok vihar</h6>
                      <h6>Date :- 28/08/2024</h6>
                      <h6>Time :- 12:00 pm </h6>
                      <p>
                        With Over 18 years of Experience in the field of
                        counselling Annant Education is a leading consulting
                        services provider company in Delhi NCR region,
                        established by Mrs. Arti Tomar. We have been providing
                        quality consulting services in the Education sector. We
                        have accepted as a preferred organization for
                        undertaking consultancy assignments.
                      </p>
                      <Link to="/Booking"><button>Book Now</button></Link>
                    </div>
                   </div>
                  </div>
                </AliceCarousel>
              </div>
            </div>
          </div>
        </div>

        <div className="Testimonial_section_main"id="EVENT">
          <div className="Testmonial_section_secound">
            <div className="container-fluid Testimonial_section_main_main_container">
              <h1>
                Getting Started is Extremely{" "}
                <span style={{ color: "#D4B26B" }}>Simple & Easy</span>
              </h1>
              <div className="row">
                <div className="col-lg-4 Extremely_Simple_section_main">
                  <center>
                    {" "}
                    <img src="/Asstes/Images/booking (2) 1.svg" alt="images" />
                  </center>
                  <div className="Text_Testimonial_main">
                    <h4>Step 1 : Schedule a Call </h4>
                    <p>
                      Schedule your discovery session with our team of business
                      consultants and ensure your presence during the call
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 Extremely_Simple_section_main">
                  <center>
                    {" "}
                    <img
                      src="/Asstes/Images/meeting.svg"
                      alt="images"
                      style={{ height: "90px" }}
                    />
                  </center>
                  <div className="Text_Testimonial_main">
                    <h4>Step 2 : Pinpoint the obstacles</h4>
                    <p>
                      pinpoint the obstacles hindering your Career progress and
                      develop a strategic plan to overcome them.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 Extremely_Simple_section_main">
                  <center>
                    {" "}
                    <img
                      src="/Asstes/Images/grow.svg"
                      alt="images"
                      style={{ height: "90px" }}
                    />
                  </center>
                  <div className="Text_Testimonial_main">
                    <h4>Step 3 : Grow your Career</h4>
                    <p>
                      Grow your Career with a reliable partners who possesses a
                      optimistic mindset and good - natured sense of humor.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="Qustion_section_main">
          <div className="Qustion_secound_section">
            <div className="container-fluid Qustion_section_container">
              <div className="row">
                <h1>
                  Common Question{" "}
                  <span style={{ color: "#AF864E" }}>Answered</span>
                </h1>
                <div className="col-lg-6 Qustion_images_section">
                  <img src="/Asstes/Images/faq 1 (1).png" />
                </div>
                <div className="col-lg-6 Qustion_section_text">
                  {data.map((item, index) => (
                    <div className="Main_qustion_section" key={index}>
                      <div
                        className="Qustion"
                        onClick={() => toggleAccordion(index)}
                      >
                        <h6>{item.title}</h6>
                        <i
                          className={`ri-arrow-${
                            activeIndex === index ? "up" : "down"
                          }-s-line`}
                        ></i>
                      </div>
                      {activeIndex === index && (
                        <div className="Answer_section">
                          <p>{item.content}</p>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="Blog_section_main">
          <div className="container-fluid Blog_section">
            <div className="row">
              <div className="Blog_text_section">
                <h1>
                  Guidance and Growth: Discover More on Our<br></br> Counseling{" "}
                  <span style={{ color: "#D4B26B" }}>Blogs</span>
                </h1>
              </div>
              <div className="col-lg-4 Blog_section_maion_div">
                <div className="Blog_section_text">
                  <div className="Text_blog">
                    <img src="/Asstes/Images/Rectangle 163851 (1).png" />
                  </div>
                  <h6>A Safe Haven for Healing</h6>
                  <p>
                    I felt encouraged and understood right away in our first
                    session. I had a judgment-free, safe space to....
                  </p>
                  <Link to="/BlogSectionDeatials">
                    <button>Read More</button>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 Blog_section_maion_div">
                <div className="Blog_section_text">
                  <div className="Text_blog">
                    <img src="/Asstes/Images/Rectangle 163851.png" />
                  </div>
                  <h6>Transformative Experience!</h6>
                  <p>
                    Although I was first apprehensive to begin therapy, the
                    support and direction this counseling service ....
                  </p>
                  <Link to="/BlogSectionDeatials">
                    <button>Read More</button>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 Blog_section_maion_div">
                <div className="Blog_section_text">
                  <div className="Text_blog">
                    <img src="/Asstes/Images/Rectangle 163851 (2).png" />
                  </div>
                  <h6>Professional and Compassionate</h6>
                  <p>
                    These counselors are extraordinarily skilled and kind. They
                    take the time to pay attention to you and .....
                  </p>
                  <Link to="/BlogSectionDeatials">
                    <button>Read More</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};
export default Home;
