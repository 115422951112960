import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'animate.css';
import "./Layout.css";
const Layout = ({ children }) => {
    const [OpenLogin, setOpenLogin] = useState(false);
    const OepnHeandle = () => {
      setOpenLogin(true);
    };
    const CloseHeandle = () => {
      setOpenLogin(false);
    };
  const menus = [
    {
      label: "Home",
      href: "/",
    },
    {
      label: "About",
      href: "#About",
    },
    {
      label: "Gallery",
      href: "#Gallery",
    },
    
    {
      label: "Blogs",
      href: "BlogSection",
    },
    {
      label: "Contact us",
      href: "#Footer",
    },
  
  ];
  const [affix, setAffix] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setAffix(true);
      console.log("OK");
    } else {
      setAffix(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div>
        
<nav  className={`navbar ${affix ? 'affix' : ''}`}>
  <div className="navbar-container container-fluid">
    <input type="checkbox" name="" id="" />
    <div className="hamburger-lines">
      <span className="line line1" />
      <span className="line line2" />
      <span className="line line3" />
    </div>
    <ul className="menu-items">
    {menus.map((item, index) => (
        <li key={index}>
          {item.routerLink ? (
            <Link to={item.routerLink}>{item.label}</Link>
          ) : (
            <a href={item.href}>{item.label}</a>
          )}
        </li>
      ))}
<Link to="/BookAppoinment">
  <button className="Login_section_button">Book appointment</button>
</Link>
    </ul>
 <img src="/Asstes/Images/Annant_Education_Logo.png" className="logo" style={{width:'150px'}}/>
  </div>
</nav> 


{
  OpenLogin && 
  <div className="lOGIN_section_pages animate__animated animate__zoomIn">
    <i class="ri-close-line"onClick={CloseHeandle}></i>
  <h4>Form Login</h4>
  <button style={{background:'#D4B26B',color:'white'}}>Login</button>
  <button onClick={OepnHeandle}>Sign up</button>
  <form>
    <div>
      <input type="email" name="email" placeholder="Email" />
    </div>
    <div>
      <input type="text" name="Password" placeholder="Password" />
    </div>
    <button className="Login_button">Login</button>
    <p>Not a member? <a href = '3'>Singup now</a></p>
  </form>
</div>
}

      <div>{children}</div>
      <footer className="Footer_section_main"id="Footer">
      <div class="container-fluid">
            <div class="footer-content pt-5 pb-5">
                <div class="row">
                    <div class="col-xl-7 col-lg-7 mb-50">
                        <div class="footer-widget">
                            <div class="footer-logo">
                                <a href="index.html"><img src="/Asstes/Images/Annant_Education_Logo.png" class="img-fluid" alt="logo" /></a>
                            </div>
                            <div class="footer-text">
                               <h1>Contact us today to start your journey with professional support
                               </h1>
                            </div>
                            <div class="footer-social-icon">
                               <input type="text" name="text" placeholder="Enter your email" className="Search_input"/>
                               <input type="submit" value='Book Appointment' className="Submit_btn"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-6 mb-30">
                        <div class="footer-widget">
                            <ul>
                                <li><a href="#">Home</a></li>
                                <li><a href="#">About us </a></li>
                                <li><a href="#">Our Team</a></li>
                                <li><a href="#">Blogs</a></li>
                                <li><a href="#">Testimonial</a></li>
                              
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-6 mb-50">
                        <div class="footer-widget">
                            <ul>
                                <li><a href="#">Contact us</a></li>
                                <li><a href="#">Contact us@ gmail.com</a></li>
                                <img src="/Asstes/Images/f_logo_RGB-Blue_512 1.png" />
                                <img src="/Asstes/Images/Group 1.png" />
                                <img src="/Asstes/Images/Icon.png" />
                            </ul>                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-xl-6 col-lg-6 text-lg-left">
                        <div class="copyright-text">
                            <p>© 2024 Annant Edu. All rights reserved.</p>
                            <div class="footer-menu d-lg-none">
                            <ul>
                                <li><a href="#">Terms of Service</a></li>
                                <li><a href="#">Privacy Policy</a></li>
                                <li><a href="#">Cookies</a></li>
                               
                            </ul>
                        </div>
                        </div>
                       
                    </div>
                    <div class="col-xl-6 col-lg-6 d-none d-lg-block">
                        <div class="footer-menu">
                            <ul>
                                <li><a href="#">Terms of Service</a></li>
                                <li><a href="#">Privacy Policy</a></li>
                                <li><a href="#">Cookies</a></li>
                               
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
