import Layout from "./Layout/Layout";

const Term = () =>{
    return(
        <div>
            <Layout>
                <div className="Term_section">
               <div className="container">
                <div className="row">
                    <div className="col-12">
                    <section id="terms-of-service">
  <div className="card">
    <h1 className="primary-heading">Terms of Service</h1>
    <p className="paragraph">
      1) In consideration of your use of the beCoditive API, you represent that
      you of legal age to form a binding
      contract and are a person barred from
      receiving services under the laws of the Indian Constitution or other
      applicable jurisdiction. You also agree to: <br /> <br />
      • provide true, accurate, current and complete information about yourself
      as prompted by beCoditive API's registration form and; <br /> <br />
      • maintain and promptly update the Registration Data to keep it true,
      accurate, current and complete. If you provide any information that is
      untrue, inaccurate, not current or incomplete, or beCoditive has
      reasonable grounds to suspect that such information is untrue, inaccurate,
      not current or incomplete, beCoditive has the right to suspend or
      terminate your account and refuse any and all current or future use of the
      beCoditive API (or any portion thereof).
      <br />
      <br />
      <br />
      2) Any kind of abusing, harassment using beCoditive API is
      strictly prohibited. If anyone is found
      conducting such acts, they will be 
      from the beCoditive API and legal action will be taken against them.
      <br />
      <br />
      <br />
      3) beCoditive API provides API key to
      each person. Trying to generate fakes API keys is strictly
      of the person.
      <br />
      <br />
      <br />
      4) beCoditive API is and if any
      acts of are discovered, legal
      action will be taken against the offender.
      <br />
      <br />
      <br />
      5) Flooding beCoditive API with false requests and false complains is
      <br />
      <br />
      <br />
      6) Registration Data and certain other information about you
      to our Privacy Policy.
      <br />
      <br />
      <br />
      7) You expressly and
      <span className="bold">agree</span> that beCoditive and its subsidiaries,
      affiliates, officers, employees, agents, partners and licensors shall not
      be liable to you for any direct, indirect, incidental, special,
      consequential or exemplary damages, including, but not limited to, damages
      for loss of profits, goodwill, use, data or other intangible losses (even
      if beCoditive has been advised of the possibility of such damages),
      resulting from the use or the inability to use beCoditive API.
      <br />
      <br />
      <br />
      8) You that beCoditive may your access to beCoditive API for
      violations of the TOS and/or requests by authorized law enforcement or
      other government agencies.
      <br />
      <br />
      <br />
      9 You acknowledge that beCoditive may establish general practices and
      limits concerning use of beCoditive API, including without limitation the
      maximum number of days that email messages, message board postings or
      other uploaded Content will be retained by beCoditive API. You further
      acknowledge that beCoditive reserves the right to modify these general
      practices and limits from time to time. beCoditive reserves the right at
      any time and from time to time to modify or discontinue, temporarily or
      permanently, beCoditive API (or any part thereof) with or without notice.
      You agree that beCoditive shall not be liable to you or to any third party
      for any modification, suspension or discontinuance of beCoditive API.
    </p>
  </div>
</section>
                    </div>
                </div>
               </div>
               </div>
            </Layout>
        </div>
    )
}
export default Term;